/* Service Section */
.services {
    width: 100%;
    padding: 2em 0;
    background-color: #F8EEF5;
}

.services h1 {
    text-align: center;
    font-size: 28px;
    color: #2F4A81;
}
/*
.service-btn {
    border: 0;
    color: #fff;
    background-color: #434242;
    outline: none;
    padding: 1em 2em;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
} */

.cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
}

.card {
    width: 100%;
    padding: 2em 0;
    cursor: default;
}
.card-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-even {
    background-color: #fff;
}
.card-odd{
    background-color: #F8EEF5;
}
.card-odd:last-child{
    background-color: #FFF5EE;
}

.card img {
    width: 48%;
}
.card .card-details{
    width: 48%;
}

.card-details h3{
    font-size: 24px;
    color: #2F4A81;
}

.card p {
    color: #a0a1a3;
}

@media screen and (max-width: 768px) {
    .cards{
        flex-direction: column;
    }
    .card{
        width: 100%;
    }
    .card .card-info{
        width: 90%;
        flex-direction: column;
    }
    .card-even .card-info{
        flex-direction: column-reverse;
    }
    .card img{
        width: 100%;
    }
    .card-info .card-details{
        width: 100%;
    }
}
