.container {
    width: 80%;
    margin: auto;
}
.about-hero{
    padding: 2em 0;
}
.about-hero img{
    width: 100%;
}
.about-hero .about-info{
    text-align: center;
    padding: 2em 0;
}
.about-info h1{
    font-size: 36px;
    color: #2f4a81;
}

.core-values{
    width: 100%;
    background-color: #f8f5f5;
    padding: 2em 0;
}
.core{
    display: flex;
    justify-content: space-between;
}
.core-left, .core-right{
    width: 45%;
}
.core-left{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
}
/* .vision, .mission, .team{

} */
.core-left h3{
    font-size: 24px;
    border-bottom: 1px solid #a9a1a2;
}

/* .core-right{

} */
.core-right h2{
    font-size: 28px;
}
.core-right .about-icons{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 2em 0;
}
.about-icons .about-icon{
    width: 40%;
    padding: 1em;
    /* border: 2px solid #222;
    border-radius: 50%; */
}

.about-icons .about-icon p{
    text-transform: capitalize;
}

/* statistics */
.statistics{
    width: 100%;
    padding: 2em 0;
    margin: 2em 0;
}
.stats{
    display: flex;
    justify-content: space-between;
}
.stat{
    line-height: 0;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
}
.stats .stat span.num{
    font-size: 28px;
    color: #2f4a81;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .container{
        width: 90%;
    }
    .core{
        flex-direction: column;
    }
    .core-left, .core-right{
        width: 100%;
        text-align: center;
        margin: 2em 0;
    }
    .core-left > div{
        padding: 2em 0;
    }
    .core-left h3{
        border: none;
        font-size: 24px;
    }
    .about-icons{
        flex-wrap: wrap;
    }
    .about-icons .about-icon{
        width: 100px;
    }
}

@media screen and (max-width: 480px) {
    .stats {
            flex-direction: column;
            justify-content: space-between;
        }
    .stat{
        margin-bottom: 1em;
    }
}
