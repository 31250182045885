.certificate{
    width: 100%;
    padding: 2em 0;
}
.certificate h1{
    text-align: center;
    margin: 1em 0;
    color: #2f4a81;
}
.certs{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cert{
    width: 33%;
}
.cert h3 {
    margin-bottom: 1em;
    color: #2f4a81;
    text-align: center;
}
.cert img{
    width: 100%;
}

@media screen and (max-width: 960px) {
    .certs{
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .certs{
        flex-direction: column;
    }
    .cert{
        width: 100%;
        margin: 2em 0;
    }
}
