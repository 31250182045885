/* Our Clients */
.clients {
    width: 100%;
    text-align: center;
    margin: 2em 0;
    padding: 2em 0;
}

.clients h1{
    color: #2F4A81;
}

.partners {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 5px;
    margin: 2em 0;
}

.partners .img-box {
    width: 15%;
    /* background-color: #f5f5f5; */
    margin: auto;
    padding: 5px 0;
}

.img-box img {
    width: 80%;
}
