/* Gallery */
.gallery {
    width: 100%;
    margin: 2em 0;
}

.gallery h1 {
    text-align: center;
    padding: 1em 0;
    color: #2F4A81;
}
.image-gallery{
    position: relative;
    width: 100%;
    margin: 2em 0;
}
.image-gallery .slide{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.image-gallery img {
    max-width: 32%;
}

.arrows{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    /* visibility: hidden; */
}
/* .arrows:hover{
    visibility: visible;
} */
.left-arrow, .right-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background-color: transparent;
    font-size: 1.5rem;
    /* background-color: #615858; */
    color: #fff;
    border-radius: 50%;
    padding: .3em .4em;
    z-index: 10;
    cursor: pointer;
}

.left-arrow {
    left: 0;
}
.right-arrow {
    right: 0;
}
.slide {
    display: none;
    transition: 1s ease-in-out;
}

.slide.active {
    display: flex;
    transition: 1s ease-in-out;
}


.gallery-btn {
    border: 0;
    color: #fff;
    background-color: #2F4A81;
    outline: none;
    padding: 1em 2em;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
}
