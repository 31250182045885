/* Footer Section */
footer {
    width: 100%;
    background-color: #1A1B27;
    color: #fff;
    padding: 2.5em 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.footer .icons img{
    margin-right: 5px;
}

.footer .info {
    width: 35%;
}
.footer .info p{
    margin: 0.3em;
}

.footer .socials{
    width: 30%;
    display: flex;
    font-size: 24px;
}
.footer .socials a i{
    color: #fff;
    padding: 0 10px;
}

@media screen and (max-width: 810px) {
    .footer{
        flex-direction: column;
    }
    .footer .icons{
        margin-bottom: 1em;
    }
    .footer .info{
        width: 100%;
        margin-bottom: 1em;
    }
}
