.hero{
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}
.hero-left, .hero-right{
    width: 50%;
}
.hero-left{
    font-weight: lighter;
}
.hero-right img{
    width: 100%;
}
.hero-left h1{
    text-transform: capitalize;
    font-size: 38px;
    font-weight: 500;
}
.hero-left h1 .text{
    color: #2F4A81;
}
.hero-left h1 .word{
    color: #E31E24;
}
p{
    margin: 2em 0;
    color: #a0a1a3;
}
.hero-btn{
    border: 0;
    color: #fff;
    background-color: #2f4a81;
    outline: none;
    padding: 1em 2em;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
}

@media screen and (max-width: 960px) {
    .hero.container {
        width: 90%;
    }
}

@media screen and (max-width: 810px) {
    .hero-right{
        display: none;
    }
    .hero-left{
        width: 100%;
        text-align: center;
    }
}

