/* About Section */

.about-us{
    background-color: #F8EEF5;
}
.abt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qualities .icon-box {
    text-align: center;
}

.right h2{
    font-size: 28px;
    color: #2F4A81;
}

.right h2 span {
    color: #dd3333;
}

.about-us {
    padding: 2em 0;
}

.left,
.right {
    width: 45%;
}

.left img {
    width: 100%;
}

.values {
    padding: 1em 0;
}

.values h2 {
    text-align: center;
    font-size: 26px;
    padding: 2em 0;
    color: #2F4A81;
}

.values .qualities {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .left {
        display: none;
    }
    .right{
        width: 100%;
    }

}
