main{
    overflow-x: hidden;
}
.contact-us{
    width: 100%;
    padding: 2em 0;
}
.contact{
    display: flex;
    justify-content: space-between;
}
.contact > div{
    width: 45%;
}
.contact div > h3{
    text-transform: capitalize;
    padding: 1em 0;
    font-size: 24px;
    color: #434242;
}

.address h4{
    font-size: 16px;
    color: #2f4a81;
}
.address .phone{
    line-height: 0;
}
.contact-right .msg{
    display: none;
    padding: .8em;
    margin: .5em 0;
    background-color: #986161;
    color: #fff;
}
.contact-right .msg.show{
    display: block;
}
.contact-right form input{
    padding: .8em 0.2em;
    margin: .5em 0;
    width: 100%;
}

textarea{
    width: 100%;
    padding: .8em 0.2em;
}

.contact-right form input[type=submit]{
    border: none;
    text-transform: capitalize;
    background-color: #2f4a81;
    color: #fff;
    cursor: pointer;
    outline: none;
}
@media screen and (max-width: 960px) {
    .container{
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .contact{
        flex-direction: column-reverse;
    }
    .contact > div{
        width: 100%;
    }
}
