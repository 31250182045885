
.albums{
    padding: 2em 0;
}
.albums h1{
    text-align: center;
}
.album{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 2em auto;
}
.album .photo{
    width: 33%;
}
.album .photo img{
    max-width: 100%;
}

@media screen and (max-width: 960px) {
    .album.container{
        width: 90%;
    }
}
